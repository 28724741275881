import { Input, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import NavigationActions from "redux/navigation/actions"; 


function CustomLinkNode({
  props,
  text,
  setSelectedNode,
  setSidebarState,
  onTextChange,
}) {
  // const{id,data} = props
  // const {data} = props

  const {
    success,
    error,
    toggleOneTimeModal,
    fetching,
    toggleSubscriptionLoader
  } = NavigationActions;
  

  const [childNodeId, setChildNodeId] = useState("");
  const [details, setDetails] = useState({
    title:"",
    link:""
  });
  const [textBoxHeight, setTextBoxHeight] = useState("46");
  const [linkBoxHeight,setLinkBoxHeight] = useState("46")

  useEffect(() => {
    if (props?.id) {
      setChildNodeId(props?.id);
    }
    if(props?.data){
      setDetails(props?.data?.value)
    }
  },[]);

  const onChange = (evt,type) => {
    const newText = evt.target.value;
    console.log(newText,"Data. id", details)

    let newData
    if(type == "title" && newText.length > 20){
      error("Max 20 characters are allowed");
      const truncatedText = newText.slice(0, 20);
      
      // Update the state with truncated title
      newData = {
        ...details,
        [type]: truncatedText
      };
    }else{
       newData = {
        ...details,
        [type]:newText
      }
    }
  

    setDetails(newData)
    // setDetails((prevDetails)=>{

    //   console.log(prevDetails,"earlier")
    //     prevDetails = {
    //         ...prevDetails,
    //         [type]:newText
    //     }

    //     console.log({prevDetails})
    //     return prevDetails
    // })

    const newHeight = evt.target.scrollHeight;

    if(type == "title"){
      const heightDifference = newHeight - textBoxHeight;
      console.log(
        newHeight,
        "newHeight",
        heightDifference,
        "heightDifference",
        textBoxHeight,
        "textBoxHeight"
      );
      setTextBoxHeight(newHeight);
      // console.log("after height update");
      onTextChange(props.id, newData, heightDifference);
    }
    else if(type == "link"){
      const heightDifference = newHeight - linkBoxHeight;
      console.log(
        newHeight,
        "newHeight",
        heightDifference,
        "heightDifference",
        linkBoxHeight,
        "linkBoxHeight"
      );
      setLinkBoxHeight(newHeight);
      // console.log("after height update");
      onTextChange(props.id, newData, heightDifference);
    }
   

  };
  useEffect(() => {
    console.log({ textBoxHeight });
  }, [textBoxHeight]);

  function extractParentId(childId) {
    // Split the childId by "-"
    const parts = childId.split("-");

    // If there is more than one part after splitting, it means it follows the pattern
    if (parts.length > 1) {
      // The first part is the parent id
      return parts[0];
    } else {
      // If there is only one part, it might be the parent id itself
      return childId;
    }
  }


  return (
    <div
      className="nodrag position-relative"
      onClick={() => {
        setSelectedNode(extractParentId(childNodeId));
        // setSidebarState(true)
      }}
      //  className="text-updater-node"
    >
      {/* <span style={{ textAlign: "center" }}>{details?.name}</span> */}

      {/* <div style={{display:"flex", justifyContent:"center"}}>
                <label htmlFor="text">Child Node : {childNodeId}</label>
                <Input onChange={onChange} type='text' />

            </div> */}

      <div className="flow-inner-row">
        <div className="flow-inner-box px-0">

        <div style={{display:'flex', flexDirection:"column"}}>
          <TextField
          className="flow-list-textarea"
            size="large"
            multiline
            value={details?.title}
            placeholder="Button Title"
            minRows={2}
            onChange={(e) => {
              onChange(e,"title");
            }}
          />
               <TextField
               className="flow-list-textarea"
            size="large"
            multiline
            value={details?.link}
            placeholder="Button Url"
            minRows={2}
            onChange={(e) => {
              onChange(e,"link");
            }}
          />
          </div>
        </div>
      </div>

    </div>
  );
}

export default CustomLinkNode;
