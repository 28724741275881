import { Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";

const handleStyle = { left: 10 };

function CustomParentForImage({
  props,
  setSidebarState,
  setSelectedNode,
  deleteParentNode,
  setPreviewState,
  addExtraNode
}) {
  // const{id,data} = props
  // const {data} = props

  const [parentId, setParentId] = useState();

  const [childNodeId, setChildNodeId] = useState("");

  useEffect(() => {
    if (props?.id) {
      setChildNodeId(props?.id);
      setParentId(props?.id);
    }
  });

  const handleDeleteParentNode = () => {
    deleteParentNode(parentId);
  };

  const handleAddExtraNode = type => {
    addExtraNode(type, extractParentId(childNodeId));
  };

  function extractParentId(childId) {
    // Split the childId by "-"
    const parts = childId.split("-");

    // If there is more than one part after splitting, it means it follows the pattern
    if (parts.length > 1) {
      // The first part is the parent id
      return parts[0];
    } else {
      // If there is only one part, it might be the parent id itself
      return childId;
    }
  }

  return (
    <>
      <div
        draggable={true}
        className="text-updater-node flow-comn-box custom-drag-handle"
        style={{ padding: "10px", margin: "8px 10px", background: "white" }}
        onClick={() => {
          setSidebarState(true);
          setSelectedNode(parentId);
        }}
      >
        <div
          className="flow-comn-close-btn"
          onClick={() => {
            handleDeleteParentNode();
          }}
          style={{ cursor: "pointer" }}
        >
          <i className="fas fa-times-circle"></i>
        </div>
        <div className="flow-title-box">
          <aside>Flow Start</aside>

          <Handle
            type="target"
            className="flow-trigger flow-trigger-fill"
          ></Handle>
          <div
            className="preview-eye-button"
            onClick={() => {
              setPreviewState(true);
            }}
            style={{ cursor: "pointer" }}
          >
            <i className="fas fa-eye"></i>
          </div>
        </div>
      </div>
      <div className="add-content-btn-box">
        <Button
          onClick={() => {
            handleAddExtraNode("customButton");
          }}
        >
          <i className="fas fa-plus"></i> Add Button
        </Button>
      </div>
    </>
  );
}

export default CustomParentForImage;
